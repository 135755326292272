.container {
  text-align: center;
}

.App-logo {
  margin-top: 4vmin;
  height: 17vh;
}

.alternate-id {
  margin-top: 2vmin;
  height: 14vh;
}

.Department {
  font-size: calc(18px + 1vmin);
  font-weight: bold;
  margin: 10px;
}

.AppTitle {
  font-size: calc(13px + 1vmin);
  font-weight: bold;
  margin-bottom: -6px;
}

.ObsTitle {
  font-size: 18px;
  font-weight: bold;
}

.table {
  padding-top: 10px;
}

#Confirmation {
  padding-top: 55px;
  color: red;
  font-weight: bold;
}

#SearchForm {
  padding-top: 55px;
}

#SearchForm input {
  margin-left: 10px;
}

#PaymentForm input {
  margin-right: 10px;
  margin-left: -2px;
}

hr {
  width: 75%;
}

dl {
  display: flex;
  margin: 7px !important;
  font-size: calc(10px + 0.5vmin);
}

dt {
  flex-basis: 45%;
  text-align: left;
  background: lightgray;
  min-width: 180px;
}

dd {
  flex-basis: 55%;
  text-align: left;
  margin: 0 !important;
  background: lightgray;
}

.btn-toolbar {
  justify-content: center !important;
}

.btn {
  margin: 2px;
}

.fine-print {
  font-size: 12px;
  justify-content: center;
}

.red-background {
    justify-content: center;
    font-size: calc(10px + 0.5vmin);
    color: white;
    background: red;
    margin-top: 10px;
}

.pay-error {
  justify-content: center;
  text-align: center;
  font-size: calc(10px + 0.5vmin);
  color: white;
  background: red;
  width: fit-content;
  margin-top: 1px;
}

.fee-disclaimer {
  font-size: 14px;
  font-weight: bold;
}

#receiver {
  display: none;
}